import { template as template_59a06036c90c4fb2997e7471c6b8e6e5 } from "@ember/template-compiler";
const FKLabel = template_59a06036c90c4fb2997e7471c6b8e6e5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
