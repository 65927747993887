import { template as template_504540cc5f5c4144aa9994bbeb7d59f8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_504540cc5f5c4144aa9994bbeb7d59f8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
